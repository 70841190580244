@import './colors';

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Ovo', serif;
  font-weight: 400;
  font-size: 18px;

  color: $text-color;
  background-color: #fff;
}

h1, h2, h3 {
  font-family: 'Alegreya', serif;

  margin: 0;
}

h4, h5, h6 {
  font-family: 'Alegreya Sans SC', serif;

  margin: 0;
}

a {
  font-family: 'Alegreya Sans SC', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  letter-spacing: 0.1em;
  text-transform: lowercase;

  color: $text-color;

  &:hover {
    color: $text-color;
    text-decoration: underline;
  }
}

button {
  &:active, &:focus {
    outline: none;
  }

  &[mat-stroked-button].mat-stroked-button-square {
    font-family: 'Alegreya Sans SC', sans-serif !important;
    text-transform: lowercase;

    font-size: 16px;

    border-radius: 0;

    border-color: #0a0a0b;
  }

  &[mat-icon-button].mat-icon-button-big {
    height: 50px;
    width: 50px;

    mat-icon {
      height: 35px;
      width: 35px;

      line-height: 35px;
      font-size: 30px !important;
    }
  }

  &.button-big {
    padding: 1rem 2rem;

    font-family: 'Alegreya Sans SC', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 22px;

    letter-spacing: 0.05em;
    text-transform: lowercase;

    color: #1f1f1f;

    border-color: #0a0a0a;

    border-radius: 0;

    &:hover {
      background-color: #ddd;
    }
  }
}

a[mat-raised-button] {
  text-transform: none;

  &:hover {
    text-decoration: none;
  }
}

.mat-step-header {
  user-select: none;
}

.mat-horizontal-content-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mat-horizontal-stepper-header-container {
  padding-bottom: 3rem;
  flex-wrap: wrap;

  .mat-step-icon-content {
    font-size: 12px;
  }
}

.stepper-hide-header .mat-horizontal-stepper-header-container {
  display: none !important;
}

@media (min-width: 768px) {
  .stepper-md-show-header .mat-horizontal-stepper-header-container {
    display: flex !important;
  }
}

.cdk-overlay-pane {
  max-width: 95vw !important;
}

.mat-divider-white {
  border-top-color: #fff !important;
}

.mat-expansion-panel-header {
  background: $highlighted-background-color !important;

  &:hover {
    background: $hover-highlighted-background-color !important;
  }

  .mat-expansion-panel-header-title {
    font-family: "Alegreya Sans SC", serif;
    font-size: 1.25rem;
    color: #0a0a0a;
  }
}

.card-box {
  border: 1px solid $border-color;
  border-radius: 6px;

  padding: 1rem;

  .card-box-header {
    font-family: "Alegreya Sans SC", serif;
    font-size: 1.25rem;

    margin: -1rem -1rem 1rem;

    padding: 0.75rem 1rem;

    background-color: $highlighted-background-color;

    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    border-bottom: 1px solid $border-color;
  }
}

.border-rounded {
  border-radius: 6px;
}

.text-strike-through {
  text-decoration: line-through;
}
