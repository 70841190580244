@media (min-width: 1500px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1400px;
  }
}

.page {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @extend .container;
}

.page-fluid {
  padding: 2rem;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-flow-wrap {
  flex-flow: wrap;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.content-center {
  display: flex;

  flex-wrap: wrap;

  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.vertical-align-top {
  vertical-align: top;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-show-container {
  .hover-show-target {
    display: none;
  }

  &:hover {
    .hover-show-target {
      display: inline-block;
    }
  }
}

.hover-fade {
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;

  user-select: none;

  &:hover {
    opacity: 0.8;
  }
}

.hover-strong {
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;

  opacity: 0.6;

  user-select: none;

  &:hover {
    opacity: 1;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-small {
  font-size: 70%;
}

@for $i from 0 through 5 {
  .z-index-#{ $i } {
    position: relative;
    z-index: $i;
  }
}
