$primary-color: #1eaefe;
$secondary-color: #81cdef;
$success-color: #baef98;
$warn-color: #e57373;

$text-color: #0a0a0a;
$border-color: #d0cece;
$highlighted-background-color: #efeded;
$hover-highlighted-background-color: #faf8f8;
$background-black: #0a0a0a;

.highlighted {
  background-color: $highlighted-background-color;
}
