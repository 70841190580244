@import '../colors';

.ngx-quill-view {
  .ql-editor {
    padding: 0;
  }
}

quill-editor {
  display: flex;
  flex-direction: column;

  height: 100%;
}

.ql-toolbar {
  background-color: $highlighted-background-color;

  border-color: $border-color !important;

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  padding-top: 9px !important;
  padding-bottom: 9px !important;

  .ql-color-picker .ql-picker-label svg,
  .ql-icon-picker .ql-picker-label svg{
    position: relative;
    top: -4px;
  }
}

.ql-toolbar + .ql-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ql-container {
  flex: 1;

  border-color: $border-color !important;

  border-radius: 6px;

  font-family: 'Ovo', serif;
  font-weight: 400;
  font-size: 18px;
  color: $text-color;

  p {
    margin-bottom: 0;
  }
}

.card-box-editor {
  position: relative;

  quill-editor {
    .ql-toolbar {
      margin-top: calc(-1rem - 1px);
      margin-right: calc(-1rem - 1px);
      margin-left: calc(-1rem - 1px);
      z-index: 1;

      text-align: right;

      & > * {
        text-align: left;
      }
    }

    .ql-container {
      margin-top: 17px;

      border: 1px solid $border-color !important;
      background-color: #faf9f9;

      border-radius: 6px;
    }
  }

  &.card-box-header-triangle {
    quill-editor {
      .ql-toolbar {
        position: relative;

        &::before {
          position: absolute;
          top: 11px;
          right: 100%;
          left: -16px;
          display: block;
          width: 0;
          height: 0;
          pointer-events: none;
          content: ' ';
          border-color: transparent;
          border-style: solid solid outset;
          border-width: 8px;
          border-right-color: $border-color;
        }

        &::after {
          position: absolute;
          top: 11px;
          right: 100%;
          left: -16px;
          display: block;
          width: 0;
          height: 0;
          pointer-events: none;
          content: " ";
          border-color: transparent;
          border-style: solid solid outset;
          margin-top: 1px;
          margin-left: 2px;
          border-width: 7px;
          border-right-color: $highlighted-background-color;
        }
      }
    }
  }
}
