/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaHrEBBsBhlBjvfkSLk96fp57F2IwN-Pw.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaHrEBBsBhlBjvfkSLk967p57F2IwN-Pw.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaHrEBBsBhlBjvfkSLk96bp57F2IwN-Pw.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaHrEBBsBhlBjvfkSLk96np57F2IwN-Pw.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaHrEBBsBhlBjvfkSLk96Xp57F2IwN-Pw.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaHrEBBsBhlBjvfkSLk96Tp57F2IwN-Pw.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaHrEBBsBhlBjvfkSLk96rp57F2IwM.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaGrEBBsBhlBjvfkSpi4L3Dwp5eKQtGBlc.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaGrEBBsBhlBjvfkSpi4L3Kwp5eKQtGBlc.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaGrEBBsBhlBjvfkSpi4L3Cwp5eKQtGBlc.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaGrEBBsBhlBjvfkSpi4L3Nwp5eKQtGBlc.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaGrEBBsBhlBjvfkSpi4L3Bwp5eKQtGBlc.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaGrEBBsBhlBjvfkSpi4L3Awp5eKQtGBlc.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../../../../assets/fonts/4UaGrEBBsBhlBjvfkSpi4L3Owp5eKQtG.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DrpG4i5hMKB8O-9VOA.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DrpG4idhMKB8O-9VOA.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DrpG4i9hMKB8O-9VOA.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DrpG4iBhMKB8O-9VOA.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DrpG4ixhMKB8O-9VOA.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DrpG4i1hMKB8O-9VOA.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DrpG4iNhMKB8O-8.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA4i5hMKB8O-9VOA.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA4idhMKB8O-9VOA.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA4i9hMKB8O-9VOA.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA4iBhMKB8O-9VOA.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA4ixhMKB8O-9VOA.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA4i1hMKB8O-9VOA.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../../../assets/fonts/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA4iNhMKB8O-8.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Ovo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../../../assets/fonts/yYLl0h7WyfzTzI443XaFxQ.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
